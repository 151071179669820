import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import I18NextHttpBackend from 'i18next-http-backend'
// import config from '../src/config'

const enTranslation = require('./locales/en/translation.json')
const plTranslation = require('./locales/pl/translation.json')
const uaTranslation = require('./locales/ua/translation.json')
const beTranslation = require('./locales/be/translation.json')

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    // backend: {
    //   loadPath: `${config.apiBaseUrl}/api/landing-locales/{{lng}}/{{ns}}`,
    // },
    resources: {
      en: { translation: enTranslation },
      pl: { translation: plTranslation },
      ua: { translation: uaTranslation },
      be: { translation: beTranslation },
    },
    lng: localStorage.getItem('lng') || undefined,
    debug: false,
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })

export default i18n
