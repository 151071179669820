import { useState, useEffect } from 'react';

export const useConfig = () => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/config.json')
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const getAppUrl = () => config?.apiBaseUrl;
  const getApiUrl = () => config?.REACT_APP_API_URL;

  return { config, loading, error, getAppUrl, getApiUrl };
};
