import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useConfig } from '../../hoocks/useConfig';
import { createApiInstance } from '../../api/api';
import LineEnd from '../../assets/pictures/LineEnd.svg';
import { Footer } from '../../components/Footer/Footer';
import { ResponsiveFooter } from '../../components/ResponsiveFooter/ResponsiveFooter';
import style from './BlockFifth.module.css';

export const BlockFifth = ({ scroll, changeLanguage }) => {
  const { t } = useTranslation();
  const { loading: configLoading, error: configError, getApiUrl } = useConfig();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const sendContactForm = async (formData) => {
    const apiUrl = getApiUrl();
    if (!apiUrl) throw new Error('API URL not configured');
    
    const apiInstance = createApiInstance({
      REACT_APP_API_URL: apiUrl
    });

    try {
      const response = await apiInstance.post('/api/contact-us', formData);
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };

  const contactMutation = useMutation(sendContactForm, {
    onSuccess: () => {
      setFormData({ name: '', email: '', message: '' });
      alert(t('FifthBlock.SuccessMessage') || 'Message sent successfully!');
    },
    onError: (error) => {
      alert(t('FifthBlock.ErrorMessage') || 'Failed to send message. Please try again.');
      console.error('Form submission error:', error);
    }
  });

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = t('FifthBlock.NameRequired') || 'Name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = t('FifthBlock.EmailRequired') || 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('FifthBlock.EmailInvalid') || 'Invalid email address';
    }
    
    if (!formData.message.trim()) {
      newErrors.message = t('FifthBlock.MessageRequired') || 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      contactMutation.mutate(formData);
    }
  };

  if (configLoading) return <div>Loading...</div>;
  if (configError) return <div>Error loading configuration</div>;

  return (
    <div id="ContactUs" className={style.fifthBlock}>
      <div className={style.container}>
        <div className={style.titleContainer}>
          <div><img src={LineEnd} alt="li" /></div>
          <form onSubmit={handleSubmit} className={style.contactWrapper}>
            <div className={style.title}>{t("FifthBlock.Contact Us")}</div>
            
            <div className={style.inputWrapper}>
              <div className={style.inputName}>{t("FifthBlock.Name")}</div>
              <input
                className={`${style.input} ${errors.name ? style.errorInput : ''}`}
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder={t("FifthBlock.Enter your name")}
              />
              {errors.name && <div className={style.errorText}>{errors.name}</div>}
            </div>

            <div className={style.inputWrapper}>
              <div className={style.inputName}>{t("FifthBlock.Email")}</div>
              <input
                className={`${style.input} ${errors.email ? style.errorInput : ''}`}
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder={t("FifthBlock.Enter your email")}
              />
              {errors.email && <div className={style.errorText}>{errors.email}</div>}
            </div>

            <div className={style.inputWrapper}>
              <div className={style.inputName}>{t("FifthBlock.How we can help you?")}</div>
              <input
                className={`${style.input} ${errors.message ? style.errorInput : ''}`}
                type="text"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder={t("FifthBlock.Leave a message")}
              />
              {errors.message && <div className={style.errorText}>{errors.message}</div>}
            </div>

            <button 
              type="submit" 
              className={style.button}
              disabled={contactMutation.isLoading}
            >
              {contactMutation.isLoading 
                ? t("FifthBlock.Sending") || 'Sending...'
                : t("FifthBlock.Submit")}
            </button>
          </form>
        </div>
        <Footer scroll={scroll}/>
        <ResponsiveFooter scroll={scroll} changeLanguage={changeLanguage}/>
        <div className={style.rights}>2024 Fleet Insider. All rights reserved</div>
      </div>
    </div>
  );
};