import axios from 'axios';

export const createApiInstance = (config) => {
  const BASE_URL = config.REACT_APP_API_URL;
  
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  });
};