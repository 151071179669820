/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback} from 'react';
import { analytics } from './analytics';
import disableScroll from 'disable-scroll';
import { useTranslation } from 'react-i18next';
import {ReactComponent as NextArrow} from '../../assets/pictures/nextArrow.svg';
import {ReactComponent as PrevArrow} from '../../assets/pictures/prevArrow.svg';
import LineLiDashboard from '../../assets/pictures/LineLiDashboard.svg';
import style from './Dashboard.module.css';

export const Dashboard = () => {
  const [imageId, setImageId] = useState(0);
  const [activeSlideContent, setActiveSlideContent] = useState(false);
  const { t } = useTranslation();
  const ref = useRef();
  let debounce = true;
  let delta
  const counter2 = useRef(0)
  const scrollHandler = useCallback((e) => {
    if(delta){
        if(window.innerWidth > 1080){
          if (window.scrollY >= ref?.current?.offsetTop - 170 && window.scrollY <= ref.current.offsetTop-130) {
            if(counter2.current <= 0 && delta < 0){
              disableScroll.off()
            } else if (counter2.current >= 3 && delta > 0) {
              disableScroll.off()
            } else {
              disableScroll.on()
            }
          }
        } else {
          disableScroll.off()
        }
    }
  }, [delta])

  const handleScroll = useCallback((e) => {
      delta = e.deltaY
      if(window.innerWidth > 1080){
        if(counter2.current <= 0 && e.deltaY < 0) {
          disableScroll.off()
        } else if (counter2.current >= 3 && e.deltaY > 0) {
          disableScroll.off()
        } else {
          if(debounce){
              debounce = false
              if (window.scrollY >= ref?.current?.offsetTop - 170 && window.scrollY <= ref?.current?.offsetTop-130) {
                window.scroll(0, ref?.current?.offsetTop - 170)
                if(e.deltaY > 0){
                  setActiveSlideContent(true);
                  setTimeout(()=>{
                    setImageId((prevState) =>  {return prevState + 1});
                    setActiveSlideContent(false);
                  }, 500)
                  ++counter2.current
                } else {
                  setActiveSlideContent(true);
                  setTimeout(()=>{
                    setImageId((prevState) =>  {return prevState - 1})
                    setActiveSlideContent(false);
                  }, 500)
                  --counter2.current
                }
              }
              setTimeout(()=>{debounce = true}, [2000])
            }
          }
        } else {
          disableScroll.off()
        }
    }, [])
  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    return () => window.removeEventListener('wheel', handleScroll);
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [])

  const handleSetSelectedId = (id) => {
    setActiveSlideContent(true);
    counter2.current = id
    setTimeout(() => {
      setImageId(id)
      setActiveSlideContent(false);
    }, 500)
  }

  return (
    <div className={style.Dashboard} ref={ref}>
    <div className={style.container}>
      <div className={style.titleContainer}>
        <div><img src={LineLiDashboard} alt=" " /></div>
        <div className={style.contentWrapper}>
          <div className={style.titleWrapper}>
            <div className={style.title}>
             {t("Dashboard.All analytics in")}<br/>
             {t("Dashboard.one place with")}<br/>
            </div>
          </div>
        <div className={style.contentContainer}>
          <div className={style.descriptionWrapper}>
            <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper} ${style.descriptionTitle}` : `${style.AnalyticsImgWrapper} ${style.descriptionTitle}`}>
              {
                analytics.map((e) => {
                  if(e.id === imageId){
                    return t(e.title)
                  }
                })
              }
            </div>
            <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper} ${style.description}` : `${style.AnalyticsImgWrapper} ${style.description}`}>
              {
              analytics.map((e) => {
                if(e.id === imageId){
                  return (
                    <span key={e.id}>{t(e.text)}</span>
                  )
                }
              })
              }
            </div>
            <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper} ${style.descriptionButton}` : `${style.AnalyticsImgWrapper} ${style.descriptionButton}`}>
              {
                analytics.map((e) => {
                  if(e.id === imageId){
                    return t(e.credo);
                  }
                })
              } 
            </div>
          </div>
          <div className={style.dashboard}>
            <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper}` : `${style.AnalyticsImgWrapper}`}>
              {
                analytics.map((e) => {
                  if(e.id === imageId){
                    return (
                      <div key={e.id} className={`${style.imgWrapper}`}>
                        <img src={e.img} className={style.analyticsImg} alt=" " />
                      </div>
                    )
                  }
                })
              }
            </div>
            <NextArrow className={`${style.prevArrow} ${imageId <= 0 ? style.disabledArrow : ""}`} onClick={()=>imageId <= 0 ? null : handleSetSelectedId(counter2.current - 1)}/>
            <PrevArrow className={`${style.nextArrow} ${imageId >= 3 ? style.disabledArrow : ""}`} onClick={()=>imageId >= 3 ? null : handleSetSelectedId(counter2.current + 1)}/>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}
